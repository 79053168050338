* {
  box-sizing: border-box;
}

body {
  background: #0c1528;
  min-height: 100vh;
  font-family: "Inter", sans-serif;
  color: #ffffff;
}

button {
  &:focus {
    outline: none;
  }
}

a:hover {
  text-decoration: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.main_container {
  padding: 50px 0 0 245px;
  color: #ffffff;
}

.blogger_container {
  padding: 50px 10vw;
  color: #ffffff;
}

.get_started_wrapper {
  padding: 0 102px 40px 102px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  .preloader_wrapper {
    top: 0;
  }
}

.block_header {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}

.default_box {
  border-radius: 10px;
  background-color: #0e1832;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.25);
}

.blocks_row_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.profile_hr {
  height: 2px;
  width: 100%;
  border: 1px solid #373947;
}

.back_link {
  padding: 10px 20px 10px 40px;
  position: relative;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  background: #232842;
  font-size: 16px;
  border-radius: 49px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  &:hover {
    img {
      -webkit-animation: back_link 1s linear infinite;
      animation: back_link 1s linear infinite;
    }
  }
  img {
    position: absolute;
    left: 20px;
    top: calc(50% - 4.5px);
  }
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.flex-center-btw {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.default_label {
  opacity: 0.5;
  font-size: 12px;
  line-height: 15px;
}

.page_title {
  color: #ffffff;
  font-family: Montserrat, sans-serif;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -2.08px;
  line-height: 22px;
  text-align: center;
}

.get_start_box {
  background: #0e1832;
  border-radius: 9px;
  padding: 24px 64px;
}

.get_start_title {
  font-family: Montserrat, sans-serif;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -0.58px;
  line-height: 30px;
  text-align: center;
}

.full_block {
  width: 100%;
}

.search_anim {
  -webkit-animation: search_block 2s linear infinite;
  animation: search_block 2s linear infinite;
}

.preloader_block {
  width: 100%;
  height: 100%;
}

.cursor_waiting {
  cursor: wait;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.blur {
  filter: blur(4px);
}

.overflow_hidden {
  overflow: hidden;
}

.loader_block {
  min-height: 100px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no_display {
  display: none;
}

.loader_over_chat {
  position: absolute;
  z-index: 100;
  width: 100%;
  background: #0d172a;
}

.margin_right_16 {
  margin-right: 16px;
}

.min_height_checkbox {
  min-width: 24px;
}

.no_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.report_user_wrapper {
  display: flex;
  flex-flow: column;
  min-width: 429px;
  h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.5824px;
  }
  form {
    & > p {
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      letter-spacing: -0.266667px;
      color: #737a93;
      margin: 16px 0 32px;
    }
    .custom_input_wrapper {
      max-width: 100%;
    }
    .custom_input {
      height: 119px !important;
    }
    textarea {
      height: 100%;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;

        background: #192036;
      }
      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: #6f7481;
      }
      &::-webkit-scrollbar-thumb:window-inactive {
        background: #6f7481;
      }
    }
  }
  .max_chars {
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: #a0a6d0;
    opacity: 0.5;
    margin-top: 6px;
  }
  .default_button_wrapper {
    text-align: center;
    margin: 32px 0 24px;
    .default_button {
      height: 40px !important;
    }
  }
  .cancel_btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
}

.report_this_user {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}

.relative_wrapper {
  width: max-content;
  position: relative;
  .user_status {
    display: flex !important;
    top: 13px !important;
    left: 0 !important;
  }
}

.width_100 {
  width: 100%;
}

.connected_platforms {
  margin-right: 5px;
  padding: 0 18px 0 4px;
  height: 16px;
  background: rgba(#2fc272, 0.1);
  border-radius: 50px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: 4px;
    top: 3px;
    background-image: url("../assets/image/claimed.svg");
    width: 12px;
    height: 12px;
  }
}

.selected_platform {
  margin-right: 8px;
  padding: 0 4px;
  height: 16px;
  background: rgba(#2fc272, 0.1);
  border-radius: 50px;
}

.most_played_games {
  margin-left: 8px;
  height: 24px;
  max-height: 24px;
}

.free_balance_dialog_btn_wrapper {
  margin: 27px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 150px !important;
    height: 45px !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
  }
  p {
    margin: 0 13px;
    font-size: 14px;
    font-weight: 500;
  }
  a {
    text-decoration: none;
    text-underline: none;
  }
  &.ok_uppercase {
    button {
      text-transform: uppercase !important;
    }
  }
}

.text_align_center {
  text-align: center;
}

.cfGMLl {
  left: unset !important;
  right: 15px !important;
}

.welcome_dialog {
  width: 400px;
  margin-top: 30px;
  text-align: center;
  .oopsie_description_block {
    span {
      font-weight: 600;
      margin-right: 4px;
    }
  }
  .default_button_wrapper {
    .default_button_big {
      width: 184px !important;
    }
  }
}

.verification_dialog {
  width: 470px;
  margin-top: 30px;
  .oopsie_title {
    margin: 0 0 24px;
    text-align: center;
    font-size: 28px !important;
    line-height: 34px !important;
  }
  .oopsie_description_block {
    padding-bottom: 8px;
  }
  .centered_button {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .verification_block {
    margin: 16px 0;
    width: 100%;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #192036;
    border-radius: 9px;
    &.first_one {
      padding-left: 56px;
      position: relative;
      &:before {
        content: "1.";
        position: absolute;
        left: 32px;
        top: 32px;
      }
    }
    &.second_one {
      padding-left: 56px;
      position: relative;
      &:before {
        position: absolute;
        content: "2.";
        left: 32px;
        top: 32px;
      }
    }
    &.display_column {
      flex-flow: column;
    }
    p {
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      img {
        margin-right: 24px;
      }
    }
    .default_button_wrapper {
      .default_button_big {
        width: 120px !important;
        height: 40px !important;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

.uppercase_btn {
  text-transform: uppercase !important;
}

.please_confirm_dialog {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  &.width-unset {
    .oopsie_description_block {
      width: unset;
    }
  }
  .oopsie_description_block {
    width: 285px;
    line-height: 19px;
  }
}

.do_no_show_anymore {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.description_under_button {
  font-size: 10px;
  text-align: center;
  margin-top: -20px;
}

.description_under_button_n2 {
  font-size: 10px;
  text-align: center;
}

.phone_number_field {
  width: 100%;
  max-width: 380px;
  position: relative;
  margin: 0 auto 20px;

  svg {
    position: absolute;
    right: 12px;
    top: calc(50% - 12px);
    fill: #f44336;
    cursor: pointer;
  }
}

.intl-tel-input {
  width: 380px;
  box-sizing: border-box;
  &.field_error {
    input {
      border-bottom: 2px solid #f44336 !important;
    }
  }
  input {
    font-family: "Inter", sans-serif;
    color: rgba(255, 255, 255, 0.72);
    display: block;
    height: 51.63px;
    width: 100%;
    background: rgba(0, 0, 0, 0.09);
    font-size: 14px;
    padding: 0 16px 0 62px !important;
    border-radius: 0;
    border: 1px solid transparent;
    border-bottom: 2px solid rgba(255, 255, 255, 0.35);
    box-sizing: border-box;
    transition: all ease 0.3s;
    &:hover {
      border-bottom: 2px solid rgba(255, 255, 255, 0.6);
    }
    &:focus {
      border-bottom: 2px solid #ffffff;
    }
    &::placeholder {
      color: rgba(255, 255, 255, 0.72);
      opacity: 0.5;
    }
  }
  .country-list {
    width: 380px;
    background: #1a2253 !important;
    border: none !important;
    border-radius: 8px;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: #192036;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: #6f7481;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: #6f7481;
    }
    .country {
      padding: 8px 0 !important;
      span {
        font-size: 14px;
        font-family: "Inter", sans-serif;
        margin-left: 20px;
      }
    }
  }
  .iti-flag {
    margin-left: 16px !important;
  }
}

.attention_dialog {
  width: 400px;
  margin-top: 30px;
  text-align: center;
  .attention_icon {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 72px;
      height: 72px;
    }
  }
  .default_button_big {
    text-transform: uppercase !important;
  }
  .attention_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.848421px;
  }
  .attention_description {
    font-size: 14px;
    line-height: 20px;
    margin: 40px auto 32px;
    max-width: 242px;
  }
  .default_button_wrapper {
    .default_button_big {
      width: 184px !important;
    }
  }
}

.tooltip_info {
  margin-left: 4px;
  cursor: pointer;
  width: 16px !important;
  height: 16px !important;
}

.tooltip_info_initial_size {
  margin-left: 4px;
  cursor: pointer;
}

.with-scrollbar {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    background: #0e1832;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #1f2d48;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: #1f2d48;
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.or_separator {
  width: 100%;
  position: relative;
  height: 1px;
  margin: 24px 0;
  & > span {
    position: absolute;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.3);
    padding: 0 16px;
    background: #192036;
    left: calc(50% - 32px);
    top: -10px;
  }
  & > div {
    background-color: rgba(255, 255, 255, 0.1);
    height: 1px;
    width: 100%;
  }
}

.are-you-sure-dialog {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }
  &__description {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.5px;
  }
  &__separator {
    height: 1px;
    width: 100%;
    background-color: #252a47;
    margin: 32px 0;
  }
  &__btn-wrapper {
    display: flex;
    align-items: center;
    .default_button_wrapper {
      margin: 0 8px;
      .default_button {
        height: 40px !important;
        width: 156px !important;
        &_cancel {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}

.platform-username-and-icon {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.5px;
  opacity: 0.5;
  img {
    margin-left: 2px;
  }
}

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: #0e1832;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
  padding: 0 30px 0 41px;
  z-index: 100000;
}

.header-logo {
  & > :first-child {
    margin-right: 20px;
  }
}

.header-options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 60%;
  color: #ffffff;
  svg {
    fill: #ffffff;
  }
  & > button {
    max-width: 50%;
  }
  button {
    padding: 0;
    display: flex;
    min-width: unset !important;
    p {
      color: #ffffff;
      font-family: "Inter", sans-serif;
      font-size: 13px;
      margin-right: 10px;
      text-transform: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .user_avatar {
    width: 24px;
    height: 24px;
  }
  .app-menu_button {
    background: #232841;
    border-radius: 16px;
    svg {
      font-size: 14px;
      margin-left: 5px;
    }
    &.menu_open {
      svg {
        transform: rotate(180deg);
      }
    }
    &:disabled {
      color: #ffffff;
    }
  }
  .language_button {
    margin: 0 12px;
    padding: 0 9px;
    width: 123px;
    img {
      margin-right: 5px;
    }
  }
  .online_users,
  .open_challenges {
    margin: 0 22px 0 12px;
    border-radius: 6px;
    width: 95px;
    padding: 6px 0;
    font-size: 12px;
    text-transform: capitalize;
    font-family: "Inter", sans-serif;
    color: #ffffff;
    .online_status {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #2fc272;
      margin: 2px 6px 0 0;
    }
    .number {
      margin-left: 4px;
      font-size: 12px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.5);
      &.active {
        color: #2fc272;
      }
    }
  }
  .open_challenges {
    margin: 0;
    padding: 6px 12px;
    min-width: 151px;
    width: max-content;
  }
}

.head_btn_wrapper {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  position: relative;
}

.head_btn_indicator {
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, #f85252 0%, #f04242 100%);
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  opacity: 0;
  transform: translateY(-100%);
  &:before {
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    position: absolute;
    left: calc(50% - 5px);
    top: -2px;
    clip-path: polygon(50% 0, 0 33%, 100% 33%);
    transform: rotate(180deg);
    background-color: #f85252;
  }
  &.indicator_active {
    opacity: 1;
    transform: translateY(0);
  }
}

div {
  .app_head_popover {
    overflow: inherit;
    background: transparent;
    margin-top: 20px;
  }
}

.app-menu {
  background-color: #292f4c;
  border: 1px solid #373947;
  padding: 8px 0;
  position: relative;
  border-radius: 6px;
  z-index: 999;
  &.main_app {
    width: 165px;
    hr {
      background-color: rgba(255, 255, 255, 0.1);
      height: 1px;
      width: calc(100% - 32px);
      margin: 8px auto;
    }
  }
  &:before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    right: 25px;
    top: -8px;
    border-left: 1px solid #373947;
    border-top: 1px solid #373947;
    background-color: #292f4c;
    -ms-transform: rotate(45deg) skew(-20deg, -20deg);
    transform: rotate(45deg) skew(-20deg, -20deg);
    z-index: 9999;
  }
  a,
  button {
    padding: 8px 16px;
    text-align: left;
    width: 100%;
    font-size: 14px;
    display: block;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.language_menu {
  padding: 10px 16px 3px;
  background: #0e1832;
  border: 1px solid #373947;
  box-sizing: border-box;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.25);
  position: relative;
  border-radius: 6px;
  z-index: 999;
  a,
  button {
    padding: 12px 0;
    text-align: left;
    width: 100%;
    font-size: 16px;
    display: block;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  button {
    &:first-of-type {
      border-bottom: 1px solid #373947;
    }
  }
  .app-header-check-mark {
    margin-right: 8px;
    height: 11px;
    width: 9px;
  }
  .language-title {
    font-size: 14px;
    color: #ffffff;
  }
}

.language_popover {
  min-width: 232px !important;
  padding-bottom: 0 !important;
}

.notification_popover {
  width: 360px !important;
  height: 164px !important;
  background: #dadada !important;
  border: 1px solid #ffffff !important;
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 6px !important;
  padding-bottom: 0 !important;
  & > div {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 18px 25px 0 34px;
    color: #000000;
    height: 125px;
    .notification_header {
      font-size: 16px;
      display: flex;
      line-height: 30px;
      .notification_icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #f32a2a;
        margin-right: 15px;
        text-align: center;
      }
    }
    .notification_text {
      font-size: 14px;
      margin: 10px 0;
    }
    .notification_buttons {
      display: flex;
      margin-left: 105px;
      justify-content: space-between;
    }
  }
}

.notification-icon {
  width: 25px;
  height: 25px;
}

.download__buttons_app {
  margin-right: 25px;
}

div {
  .tabs_buttons_container {
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

div {
  .tab_wrapper {
    font-family: "Inter", sans-serif;
    text-transform: inherit;
    font-size: 16px;
    letter-spacing: -0.5px;
    opacity: 0.5;
    font-weight: 400;
    min-height: 52px;
    min-width: 60px;
    max-width: unset;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    &:hover {
      opacity: 1;
    }
    &.tab_selected {
      opacity: 1;
    }
  }
}

.public_page_wrapper {
  min-height: 100vh;
  //min-width: 100vw;
  .public_content {
    h1 {
      color: white;
      font-weight: bold;
      max-width: 65%;
      margin: 0.7em 0;
      line-height: 1.1em;
      font-size: 3.5rem;
      &.no_max_width {
        max-width: 100%;
      }
    }
    p {
      font-size: 1rem;
      margin: 1em 0;
      &.align_right {
        width: 100%;
        text-align: right;
      }
    }
    h3 {
      color: white;
      margin: 0.7em 0 0.7em 0;
      font-weight: 500;
      line-height: 1.2;
      font-size: 1.75rem;
    }
    ul {
      margin-top: 0;
      margin-bottom: 1rem;
      li {
        display: list-item;
        margin: 1em 0;
        margin-left: 1rem;
        font-size: 1rem;
        list-style: decimal;
        ol {
          margin-top: 0;
          margin-bottom: 1rem;
          li {
            display: list-item;
            margin: 1em 0;
            margin-left: 1rem;
            font-size: 1rem;
            list-style: disc;
          }
        }
      }
    }
  }
}

.for_some {
  padding: 80px 15vw;
}

.hero_title {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 48px;
}

.hero_video-wrapper {
  width: 100%;
  border-radius: 16px;
  padding-bottom: 116px;
  .hero_video {
    width: 100%;
    height: 625px;
    opacity: 0.62;
    border-radius: 16px;
  }
}

.download_title {
  font-size: 36px;
  max-width: 800px;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 32px;
}

.download-wrapper {
  text-align: center;
}

.download_subtitle {
  font-weight: 500;
  margin-bottom: 32px;
}

//.download__buttons_google {
//  height: 40px;
//  max-height: 40px;
//}

.features_title {
  font-weight: 500;
  text-align: center;
  margin-bottom: 56px;
  font-size: 36px;
}

.features_section {
  padding-bottom: 120px;
}

.download_section {
  padding-bottom: 152px;
}

.features__items {
  display: flex;
  .features__item--outer {
    flex-basis: 33.3%;
    min-height: 263px;
    margin-right: 32px;
    margin-bottom: 32px;
    background: #192036;
    border-radius: 9px;
    .features__item--inner {
      padding: 40px 25px 25px;
      text-align: center;
      .features__item_icon {
        margin-bottom: 16px;
      }
      .features__item_title {
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 24px;
      }
      .features__item_text {
        padding: 0 10px;
        font-weight: normal;
        font-size: 14px;
        color: #737a93;
      }
    }
  }
}

.stars-wrapper {
  text-align: center;
}

.stars_title {
  font-weight: 500;
  text-align: center;
  margin-bottom: 40px;
  font-size: 36px;
}

.stars_subtitle {
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #a0a6d0;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 55px;
}

.stars__buttons_app {
  margin-right: 25px;
}

//.stars__buttons_google {
//  height: 40px;
//  max-height: 40px;
//}

@media screen and (max-width: 1300px) {
  .public_page_wrapper {
    .public_content {
      box-sizing: border-box;
      width: calc(100vw - 32px);
      margin: 0 16px;
    }
  }
  .hero_title {
    margin-top: 20px;
    font-size: 32px;
  }
  .for_some {
    padding: 50px 0;
  }
  .public_head {
    padding: 0 20px !important;
  }
  .download_section {
    padding-bottom: 116px;
    margin: 0 16px;
  }
  .features_section {
    padding-bottom: 90px;
  }
  .features__items {
    flex-direction: column;
    margin: 0 auto;
    max-width: 400px;
    .features__item--outer {
      flex-basis: 100%;
      margin: 0 16px 32px 16px;
    }
  }
  .stars_title,
  .features_title,
  .download_title {
    font-size: 30px;
  }
  .stars-wrapper {
    padding: 0 10px;
  }
  .hero_video-wrapper {
    width: 100%;
    .hero_video {
      width: 100%;
      height: 200px;
    }
  }
  .download__buttons {
    display: flex;
    flex-flow: column;
    .download__buttons_app {
      margin-right: 0;
      margin-bottom: 25px;
    }
  }
  .stars__buttons {
    display: flex;
    flex-flow: column;
    .download__buttons_app {
      margin-right: 0;
      margin-bottom: 25px;
    }
  }
}

.public_head {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 50px;
  background-color: #0e1832;
  padding: 0 15vw;
  z-index: 99;
  &.affiliate_padding {
    padding: 0 132px;
  }
  & > img {
    cursor: pointer;
  }
  a {
    text-decoration-line: underline;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
  }
  .header-options {
    display: flex;
    align-items: center;
    color: #ffffff;
    min-width: unset;
    svg {
      fill: #ffffff;
    }
    button {
      display: flex;
      min-width: 125px !important;
      width: unset;
      max-width: 125px !important;
      p {
        color: #ffffff;
        font-family: "Inter", sans-serif;
        font-size: 13px;
        margin-right: 10px;
        text-transform: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .user_avatar {
      width: 24px;
      height: 24px;
    }
    .app-menu_button {
      background: #232841;
      border-radius: 16px;
      svg {
        font-size: 14px;
        margin-left: 5px;
      }
      &.menu_open {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .language_button {
      padding: 0 9px;
      img {
        margin-right: 5px;
      }
    }
  }
}

.header-logo {
  & > :first-child {
    margin-right: 20px;
  }
}

@media screen and (max-width: 420px) {
  .public_head {
    height: 60px;
  }
  .header-options {
    a {
      font-size: 12px;
      margin-bottom: 10px;
    }
    flex-flow: column;
  }
}

.auth_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, #000b47 0%, #0d146e 100%);
  &.login_page {
    align-items: unset;
  }

  &.auth-login-form {
    .inputs_wrapper {
      margin: 0 auto;
      max-width: 380px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .custom_input_wrapper {
        width: 100%;
        &:first-of-type {
          margin-right: 12px;
        }
      }

      &.with-postcode {
        .custom_input_wrapper {
          &:first-of-type {
            width: 150px;
            max-width: 150px;
          }
          &:last-of-type {
            width: 218px;
            max-width: 218px;
          }
        }
      }
    }
    .select_wrapper {
      margin: 0 auto;
      max-width: 380px;
      width: 100%;
      margin-bottom: 20px;
      height: 51px;
      position: relative;
      .select_component .select__control .select__placeholder {
        font-size: 14px;
        line-height: 17px;
      }
      .select_component .select__control {
        background-color: rgba(0, 0, 0, 0.09);
      }
      .select_component .select__control .select__value-container {
        margin-top: 16px;
      }
      input {
        width: unset;
      }
      .select-label {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        color: rgba(255, 255, 255, 0.72);
        font-size: 14px;
        opacity: 0.5;
        pointer-events: none;
        transition: 0.2s ease all;
        transform: translate(14px, 4px) scale(0.75);
      }
    }
  }
}

.auth_section {
  padding: 128px 0 50px;
  width: 55%;
  min-height: 100vh;
  background-color: #1b2664;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.round_inside_block {
  background: linear-gradient(180deg, #000b47 0%, #0d146e 100%);
  clip-path: ellipse(60% 53% at 30% 50%);
  left: -10%;
  top: 0;
  height: 100%;
  position: absolute;
  width: 20%;
}

.auth_center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

.auth_section_block {
  max-width: 380px;
  .tab_wrapper {
    font-size: 14px;
  }
  form {
    margin-top: 24px;
    .custom_input_wrapper {
      margin-bottom: 20px;
    }
    .inputs_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .custom_input_wrapper {
        width: 100%;
        &:first-of-type {
          margin-right: 12px;
        }
      }

      &.with-postcode {
        .custom_input_wrapper {
          &:first-of-type {
            width: 150px;
            max-width: 150px;
          }
          &:last-of-type {
            width: 218px;
            max-width: 218px;
          }
        }
      }
    }
    .select_wrapper {
      margin-bottom: 16px;
      width: 100%;
      height: 51px;
      position: relative;
      .select_component .select__control .select__placeholder {
        font-size: 14px;
        line-height: 17px;
      }
      .select_component .select__control {
        background-color: rgba(0, 0, 0, 0.09);
      }
      .select_component .select__control .select__value-container {
        margin-top: 16px;
      }
      input {
        width: unset;
      }
      .select-label {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        color: rgba(255, 255, 255, 0.72);
        font-size: 14px;
        opacity: 0.5;
        pointer-events: none;
        transition: 0.2s ease all;
        transform: translate(14px, 4px) scale(0.75);
      }
    }
  }
}

.auth-label-with-tooltip {
  display: flex;
  align-items: center;
  column-gap: 4px;

  img {
    cursor: pointer;
    width: 21px;
    height: 21px;
    pointer-events: visible;
  }
}

.auth_title {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 32px;
}

.auth_subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 39px;
  strong {
    color: white;
    font-weight: normal;
  }
}

.auth_subtitle_email {
  color: #737a93;
  max-width: 500px !important;
  margin: 0 auto 27px !important;
}

.auth_promo_wrapper {
  padding: 128px 0 50px;
  width: 45%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth_promo {
  z-index: 20;
  position: relative;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .payment_logos {
    margin-top: 30px;
  }
  .download__buttons {
    margin-top: 32px;
  }
}

.promo_image {
  position: relative;
  height: 520px;
  img {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-height: 100%;
  }
}

.promo_text {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.3px;
  max-width: 440px;
}

.promo_text_bottom {
  margin-top: 46px;
}

.en_video {
  width: 100%;
  min-height: 300px;
}

div {
  .play_button {
    height: 106px;
    width: 106px;
    background-color: #ffffff;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    left: calc(50% - 53px);
    position: absolute;
    z-index: 3;
    &:hover {
      background-color: rgba(255, 255, 255, 0.7);
    }
    &.login_play_button {
      top: calc(53% - 53px);
    }
    &.register_play_button {
      top: calc(53% - 53px);
    }
    &.recovery_play_button {
      top: calc(50% - 53px);
      left: calc(51% - 53px);
    }
  }
}

.auth_buttons_wrapper {
  margin: 24px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .register_button {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: max-content;
    .no_deposit_required {
      margin-top: 12px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #a0a6d0;
    }
  }
  .auth_button_wrapper {
    display: inline-block;
    .auth_button {
      min-width: 180px !important;
    }
  }
}

.auth_nav_link {
  color: #a0a6d0;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  &:hover {
    text-decoration: none;
  }
}

.auth_link {
  color: #a0a6d0;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.auth_login_options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 24px;
  a {
    font-size: 12px !important;
  }
}

.recovery_buttons_wrapper {
  .auth_button_wrapper {
    margin-right: 50px;
  }
}

.auth_steps_box {
  margin-top: 128px;
  min-height: 360px;
  min-width: 558px;
  border-radius: 13px;
  background-color: #0e1832;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.25);
  text-align: center;
  .auth_title {
    font-size: 38px;
  }
  .auth_subtitle {
    font-size: 16px;
  }
  .auth_button_wrapper {
    margin-bottom: 22px;
  }
}

.recovery_first_step_wrapper {
  padding: 71px 0 34px;
  .custom_input_wrapper {
    margin-bottom: 35px;
  }
}

.code_confirm_wrapper {
  padding: 43px 0 43px;
  img {
    margin-bottom: 34px;
  }
  .custom_input_wrapper {
    margin-bottom: 24px;
  }
  .auth_subtitle {
    margin-bottom: 27px;
  }
}

.facebook-auth_wrapper {
  padding: 37px 0 34px;
  .auth_subtitle {
    max-width: 500px !important;
    margin: 0 auto 39px !important;
  }
  .custom_input_wrapper {
    margin-bottom: 20px;
  }
}

.set_password_wrapper {
  padding: 54px 0 40px;
  img {
    margin-bottom: 12px;
  }
  .custom_input_wrapper {
    margin-bottom: 18px;
  }
  .auth_button_wrapper {
    margin-bottom: 0;
  }
}

.resend_wrapper {
  position: relative;
  display: inline-block;
  color: #737a93;
  .break-line {
    height: 25px;
  }
  div {
    margin-top: 18px;
    margin-bottom: 18px;
  }
  span {
    color: #737a93;
  }
}

.success_resend {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  border-radius: 50%;
  padding: 2px;
  position: absolute;
  right: -30%;
  top: calc(50% - 11px);
  svg {
    fill: #ffffff;
    font-size: 18px;
  }
}

.logo_console {
  width: 50px;
  height: 50px;
  &.width-18 {
    width: 18px;
    height: 18px;
  }
  &.width-62 {
    width: 62px;
    height: 62px;
  }
}

.scroll-down-btn {
  position: fixed !important;
  right: 10px;
  bottom: 10px;
  width: 180px;
  img {
    height: 100%;
    width: 100%;
  }
}

.deposit-to-play-dialog {
  text-align: center;
  padding: 24px 0;

  &__title {
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  &__description {
    margin-bottom: 48px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .or_separator > span {
    background: #0e1832;
  }

  .default_button,
  .auth_button {
    border-radius: 22.5px !important;
    width: 186px !important;
    min-width: 186px !important;
    height: 45px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    text-transform: none !important;
  }
}

.balance_green {
  color: #2fc272;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
}

.input-field-with-currency {
  width: 100%;
  position: relative;

  &__currency {
    position: absolute;
    right: 8px;
    top: 16px;
    font-weight: 600;
    font-size: 14px;
    opacity: 0.5;
  }
}
