@-webkit-keyframes back_link {
  0%   {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  50%   {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes back_link {
  0%   {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  50%   {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes skip_link {
  0%   {
    -webkit-transform: translateX(0) rotate(180deg);
    -ms-transform: translateX(0) rotate(180deg);
    transform: translateX(0) rotate(180deg);
  }
  50%   {
    -webkit-transform: translateX(10px) rotate(180deg);
    -ms-transform: translateX(10px) rotate(180deg);
    transform: translateX(10px) rotate(180deg);
  }
  100% {
    -webkit-transform: translateX(0) rotate(180deg);
    -ms-transform: translateX(0) rotate(180deg);
    transform: translateX(0) rotate(180deg);
  }
}
@keyframes skip_link {
  0%   {
    -webkit-transform: translateX(0) rotate(180deg);
    -ms-transform: translateX(0) rotate(180deg);
    transform: translateX(0) rotate(180deg);
  }
  50%   {
    -webkit-transform: translateX(10px) rotate(180deg);
    -ms-transform: translateX(10px) rotate(180deg);
    transform: translateX(10px) rotate(180deg);
  }
  100% {
    -webkit-transform: translateX(0) rotate(180deg);
    -ms-transform: translateX(0) rotate(180deg);
    transform: translateX(0) rotate(180deg);
  }
}

@-webkit-keyframes search_block {
  0%   {
    -webkit-transform: translate(-20px, 0px);
    -ms-transform: translate(-20px, 0px);
    transform: translate(-20px, 0px);
  }
  25% {
    -webkit-transform: translate(0px, -20px);
    -ms-transform: translate(0px, -20px);
    transform: translate(0px, -20px);
  }
  50%   {
    -webkit-transform: translate(20px, 0px);
    -ms-transform: translate(20px, 0px);
    transform: translate(20px, 0px);
  }
  75% {
    -webkit-transform: translate(0px, 20px);
    -ms-transform: translate(0px, 20px);
    transform: translate(0px, 20px);
  }
  100%   {
    -webkit-transform: translate(-20px, 0px);
    -ms-transform: translate(-20px, 0px);
    transform: translate(-20px, 0px);
  }
}
@keyframes search_block {
  0%   {
    -webkit-transform: translate(-20px, 0px);
    -ms-transform: translate(-20px, 0px);
    transform: translate(-20px, 0px);
  }
  25% {
    -webkit-transform: translate(0px, -20px);
    -ms-transform: translate(0px, -20px);
    transform: translate(0px, -20px);
  }
  50%   {
    -webkit-transform: translate(20px, 0px);
    -ms-transform: translate(20px, 0px);
    transform: translate(20px, 0px);
  }
  75% {
    -webkit-transform: translate(0px, 20px);
    -ms-transform: translate(0px, 20px);
    transform: translate(0px, 20px);
  }
  100%   {
    -webkit-transform: translate(-20px, 0px);
    -ms-transform: translate(-20px, 0px);
    transform: translate(-20px, 0px);
  }
}

.is_loading {
  position: relative;
  overflow: hidden;
  .impulse{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    transform: rotate(45deg);
    background: #bdbdbd;
    animation-name: moveit;
    animation: moveit 1s infinite;
    box-shadow: 0 0 50px 14px rgba(255,255,255,0.2);

  }
}

@-webkit-keyframes moveit {
  from {
    left: -100;
    top: -100%;
  }
  to {
    left: 300%;
    top: 300%;
  }
}

@keyframes moveit {
  from {
    left: 0;
    top: 0;
  }
  to {
    left: 300%;
    top: 300%;
  }
}