@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,900);
@import url(https://fonts.googleapis.com/css?family=Rubik:300,400);
a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, font, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, meter, nav, object, ol, output, p, pre, progress, q, rp, rt, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%; }

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block; }

b, strong {
  font-weight: bold; }

img {
  color: transparent;
  font-size: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic; }

li {
  display: list-item;
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th, td, caption {
  font-weight: normal;
  vertical-align: top;
  text-align: left; }

q {
  quotes: none; }

q:before, q:after {
  content: '';
  content: none; }

sub, sup, small {
  font-size: 75%; }

sub, sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

svg {
  overflow: hidden; }

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none; }

.clear:after, .clear:before {
  clear: both;
  display: block;
  zoom: 1;
  content: ''; }

* {
  box-sizing: border-box; }

body {
  background: #0c1528;
  min-height: 100vh;
  font-family: "Inter", sans-serif;
  color: #ffffff; }

button:focus {
  outline: none; }

a:hover {
  text-decoration: none; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.main_container {
  padding: 50px 0 0 245px;
  color: #ffffff; }

.blogger_container {
  padding: 50px 10vw;
  color: #ffffff; }

.get_started_wrapper {
  padding: 0 102px 40px 102px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh; }
  .get_started_wrapper .preloader_wrapper {
    top: 0; }

.block_header {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px; }

.default_box {
  border-radius: 10px;
  background-color: #0e1832;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.25); }

.blocks_row_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.profile_hr {
  height: 2px;
  width: 100%;
  border: 1px solid #373947; }

.back_link {
  padding: 10px 20px 10px 40px;
  position: relative;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  background: #232842;
  font-size: 16px;
  border-radius: 49px;
  transition: all 0.3s linear; }
  .back_link:hover img {
    -webkit-animation: back_link 1s linear infinite;
    animation: back_link 1s linear infinite; }
  .back_link img {
    position: absolute;
    left: 20px;
    top: calc(50% - 4.5px); }

.flex-center {
  display: flex;
  align-items: center; }

.flex-col {
  display: flex;
  flex-flow: column;
  align-items: center; }

.flex-center-btw {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.default_label {
  opacity: 0.5;
  font-size: 12px;
  line-height: 15px; }

.page_title {
  color: #ffffff;
  font-family: Montserrat, sans-serif;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -2.08px;
  line-height: 22px;
  text-align: center; }

.get_start_box {
  background: #0e1832;
  border-radius: 9px;
  padding: 24px 64px; }

.get_start_title {
  font-family: Montserrat, sans-serif;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -0.58px;
  line-height: 30px;
  text-align: center; }

.full_block {
  width: 100%; }

.search_anim {
  -webkit-animation: search_block 2s linear infinite;
  animation: search_block 2s linear infinite; }

.preloader_block {
  width: 100%;
  height: 100%; }

.cursor_waiting {
  cursor: wait; }

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none; }

.blur {
  -webkit-filter: blur(4px);
          filter: blur(4px); }

.overflow_hidden {
  overflow: hidden; }

.loader_block {
  min-height: 100px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.no_display {
  display: none; }

.loader_over_chat {
  position: absolute;
  z-index: 100;
  width: 100%;
  background: #0d172a; }

.margin_right_16 {
  margin-right: 16px; }

.min_height_checkbox {
  min-width: 24px; }

.no_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.report_user_wrapper {
  display: flex;
  flex-flow: column;
  min-width: 429px; }
  .report_user_wrapper h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.5824px; }
  .report_user_wrapper form > p {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.266667px;
    color: #737a93;
    margin: 16px 0 32px; }
  .report_user_wrapper form .custom_input_wrapper {
    max-width: 100%; }
  .report_user_wrapper form .custom_input {
    height: 119px !important; }
  .report_user_wrapper form textarea {
    height: 100%; }
    .report_user_wrapper form textarea::-webkit-scrollbar {
      width: 5px;
      height: 5px; }
    .report_user_wrapper form textarea::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: #192036; }
    .report_user_wrapper form textarea::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #6f7481; }
    .report_user_wrapper form textarea::-webkit-scrollbar-thumb:window-inactive {
      background: #6f7481; }
  .report_user_wrapper .max_chars {
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: #a0a6d0;
    opacity: 0.5;
    margin-top: 6px; }
  .report_user_wrapper .default_button_wrapper {
    text-align: center;
    margin: 32px 0 24px; }
    .report_user_wrapper .default_button_wrapper .default_button {
      height: 40px !important; }
  .report_user_wrapper .cancel_btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    transition: all 0.2s linear;
    cursor: pointer; }
    .report_user_wrapper .cancel_btn:hover {
      opacity: 0.5; }

.report_this_user {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  transition: all 0.2s linear;
  cursor: pointer; }
  .report_this_user:hover {
    opacity: 0.5; }

.relative_wrapper {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative; }
  .relative_wrapper .user_status {
    display: flex !important;
    top: 13px !important;
    left: 0 !important; }

.width_100 {
  width: 100%; }

.connected_platforms {
  margin-right: 5px;
  padding: 0 18px 0 4px;
  height: 16px;
  background: rgba(47, 194, 114, 0.1);
  border-radius: 50px;
  position: relative; }
  .connected_platforms:after {
    content: "";
    position: absolute;
    right: 4px;
    top: 3px;
    background-image: url(/static/media/claimed.2c00267a.svg);
    width: 12px;
    height: 12px; }

.selected_platform {
  margin-right: 8px;
  padding: 0 4px;
  height: 16px;
  background: rgba(47, 194, 114, 0.1);
  border-radius: 50px; }

.most_played_games {
  margin-left: 8px;
  height: 24px;
  max-height: 24px; }

.free_balance_dialog_btn_wrapper {
  margin: 27px 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .free_balance_dialog_btn_wrapper button {
    width: 150px !important;
    height: 45px !important;
    text-transform: capitalize !important;
    font-weight: 400 !important; }
  .free_balance_dialog_btn_wrapper p {
    margin: 0 13px;
    font-size: 14px;
    font-weight: 500; }
  .free_balance_dialog_btn_wrapper a {
    text-decoration: none;
    text-underline: none; }
  .free_balance_dialog_btn_wrapper.ok_uppercase button {
    text-transform: uppercase !important; }

.text_align_center {
  text-align: center; }

.cfGMLl {
  left: unset !important;
  right: 15px !important; }

.welcome_dialog {
  width: 400px;
  margin-top: 30px;
  text-align: center; }
  .welcome_dialog .oopsie_description_block span {
    font-weight: 600;
    margin-right: 4px; }
  .welcome_dialog .default_button_wrapper .default_button_big {
    width: 184px !important; }

.verification_dialog {
  width: 470px;
  margin-top: 30px; }
  .verification_dialog .oopsie_title {
    margin: 0 0 24px;
    text-align: center;
    font-size: 28px !important;
    line-height: 34px !important; }
  .verification_dialog .oopsie_description_block {
    padding-bottom: 8px; }
  .verification_dialog .centered_button {
    display: flex;
    justify-content: center;
    margin-top: 40px; }
  .verification_dialog .verification_block {
    margin: 16px 0;
    width: 100%;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #192036;
    border-radius: 9px; }
    .verification_dialog .verification_block.first_one {
      padding-left: 56px;
      position: relative; }
      .verification_dialog .verification_block.first_one:before {
        content: "1.";
        position: absolute;
        left: 32px;
        top: 32px; }
    .verification_dialog .verification_block.second_one {
      padding-left: 56px;
      position: relative; }
      .verification_dialog .verification_block.second_one:before {
        position: absolute;
        content: "2.";
        left: 32px;
        top: 32px; }
    .verification_dialog .verification_block.display_column {
      flex-flow: column; }
    .verification_dialog .verification_block p {
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center; }
      .verification_dialog .verification_block p img {
        margin-right: 24px; }
    .verification_dialog .verification_block .default_button_wrapper .default_button_big {
      width: 120px !important;
      height: 40px !important;
      font-size: 14px;
      line-height: 18px; }

.uppercase_btn {
  text-transform: uppercase !important; }

.please_confirm_dialog {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center; }
  .please_confirm_dialog.width-unset .oopsie_description_block {
    width: unset; }
  .please_confirm_dialog .oopsie_description_block {
    width: 285px;
    line-height: 19px; }

.do_no_show_anymore {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; }

.description_under_button {
  font-size: 10px;
  text-align: center;
  margin-top: -20px; }

.description_under_button_n2 {
  font-size: 10px;
  text-align: center; }

.phone_number_field {
  width: 100%;
  max-width: 380px;
  position: relative;
  margin: 0 auto 20px; }
  .phone_number_field svg {
    position: absolute;
    right: 12px;
    top: calc(50% - 12px);
    fill: #f44336;
    cursor: pointer; }

.intl-tel-input {
  width: 380px;
  box-sizing: border-box; }
  .intl-tel-input.field_error input {
    border-bottom: 2px solid #f44336 !important; }
  .intl-tel-input input {
    font-family: "Inter", sans-serif;
    color: rgba(255, 255, 255, 0.72);
    display: block;
    height: 51.63px;
    width: 100%;
    background: rgba(0, 0, 0, 0.09);
    font-size: 14px;
    padding: 0 16px 0 62px !important;
    border-radius: 0;
    border: 1px solid transparent;
    border-bottom: 2px solid rgba(255, 255, 255, 0.35);
    box-sizing: border-box;
    transition: all ease 0.3s; }
    .intl-tel-input input:hover {
      border-bottom: 2px solid rgba(255, 255, 255, 0.6); }
    .intl-tel-input input:focus {
      border-bottom: 2px solid #ffffff; }
    .intl-tel-input input::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.72);
      opacity: 0.5; }
    .intl-tel-input input::-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.72);
      opacity: 0.5; }
    .intl-tel-input input::placeholder {
      color: rgba(255, 255, 255, 0.72);
      opacity: 0.5; }
  .intl-tel-input .country-list {
    width: 380px;
    background: #1a2253 !important;
    border: none !important;
    border-radius: 8px; }
    .intl-tel-input .country-list::-webkit-scrollbar {
      width: 5px;
      height: 5px; }
    .intl-tel-input .country-list::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: #192036; }
    .intl-tel-input .country-list::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #6f7481; }
    .intl-tel-input .country-list::-webkit-scrollbar-thumb:window-inactive {
      background: #6f7481; }
    .intl-tel-input .country-list .country {
      padding: 8px 0 !important; }
      .intl-tel-input .country-list .country span {
        font-size: 14px;
        font-family: "Inter", sans-serif;
        margin-left: 20px; }
  .intl-tel-input .iti-flag {
    margin-left: 16px !important; }

.attention_dialog {
  width: 400px;
  margin-top: 30px;
  text-align: center; }
  .attention_dialog .attention_icon {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .attention_dialog .attention_icon img {
      width: 72px;
      height: 72px; }
  .attention_dialog .default_button_big {
    text-transform: uppercase !important; }
  .attention_dialog .attention_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.848421px; }
  .attention_dialog .attention_description {
    font-size: 14px;
    line-height: 20px;
    margin: 40px auto 32px;
    max-width: 242px; }
  .attention_dialog .default_button_wrapper .default_button_big {
    width: 184px !important; }

.tooltip_info {
  margin-left: 4px;
  cursor: pointer;
  width: 16px !important;
  height: 16px !important; }

.tooltip_info_initial_size {
  margin-left: 4px;
  cursor: pointer; }

.with-scrollbar {
  overflow: auto; }
  .with-scrollbar::-webkit-scrollbar {
    width: 4px;
    height: 4px; }
  .with-scrollbar::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    background: #0e1832; }
  .with-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #1f2d48; }
  .with-scrollbar::-webkit-scrollbar-thumb:window-inactive {
    background: #1f2d48; }

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.or_separator {
  width: 100%;
  position: relative;
  height: 1px;
  margin: 24px 0; }
  .or_separator > span {
    position: absolute;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.3);
    padding: 0 16px;
    background: #192036;
    left: calc(50% - 32px);
    top: -10px; }
  .or_separator > div {
    background-color: rgba(255, 255, 255, 0.1);
    height: 1px;
    width: 100%; }

.are-you-sure-dialog {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center; }
  .are-you-sure-dialog__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px; }
  .are-you-sure-dialog__description {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.5px; }
  .are-you-sure-dialog__separator {
    height: 1px;
    width: 100%;
    background-color: #252a47;
    margin: 32px 0; }
  .are-you-sure-dialog__btn-wrapper {
    display: flex;
    align-items: center; }
    .are-you-sure-dialog__btn-wrapper .default_button_wrapper {
      margin: 0 8px; }
      .are-you-sure-dialog__btn-wrapper .default_button_wrapper .default_button {
        height: 40px !important;
        width: 156px !important; }
        .are-you-sure-dialog__btn-wrapper .default_button_wrapper .default_button_cancel {
          background: rgba(255, 255, 255, 0.1); }

.platform-username-and-icon {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.5px;
  opacity: 0.5; }
  .platform-username-and-icon img {
    margin-left: 2px; }

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: #0e1832;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
  padding: 0 30px 0 41px;
  z-index: 100000; }

.header-logo > :first-child {
  margin-right: 20px; }

.header-options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 60%;
  color: #ffffff; }
  .header-options svg {
    fill: #ffffff; }
  .header-options > button {
    max-width: 50%; }
  .header-options button {
    padding: 0;
    display: flex;
    min-width: unset !important; }
    .header-options button p {
      color: #ffffff;
      font-family: "Inter", sans-serif;
      font-size: 13px;
      margin-right: 10px;
      text-transform: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .header-options .user_avatar {
    width: 24px;
    height: 24px; }
  .header-options .app-menu_button {
    background: #232841;
    border-radius: 16px; }
    .header-options .app-menu_button svg {
      font-size: 14px;
      margin-left: 5px; }
    .header-options .app-menu_button.menu_open svg {
      transform: rotate(180deg); }
    .header-options .app-menu_button:disabled {
      color: #ffffff; }
  .header-options .language_button {
    margin: 0 12px;
    padding: 0 9px;
    width: 123px; }
    .header-options .language_button img {
      margin-right: 5px; }
  .header-options .online_users,
  .header-options .open_challenges {
    margin: 0 22px 0 12px;
    border-radius: 6px;
    width: 95px;
    padding: 6px 0;
    font-size: 12px;
    text-transform: capitalize;
    font-family: "Inter", sans-serif;
    color: #ffffff; }
    .header-options .online_users .online_status,
    .header-options .open_challenges .online_status {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #2fc272;
      margin: 2px 6px 0 0; }
    .header-options .online_users .number,
    .header-options .open_challenges .number {
      margin-left: 4px;
      font-size: 12px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.5); }
      .header-options .online_users .number.active,
      .header-options .open_challenges .number.active {
        color: #2fc272; }
  .header-options .open_challenges {
    margin: 0;
    padding: 6px 12px;
    min-width: 151px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content; }

.head_btn_wrapper {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  position: relative; }

.head_btn_indicator {
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, #f85252 0%, #f04242 100%);
  transition: all 0.3s linear;
  opacity: 0;
  transform: translateY(-100%); }
  .head_btn_indicator:before {
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    position: absolute;
    left: calc(50% - 5px);
    top: -2px;
    -webkit-clip-path: polygon(50% 0, 0 33%, 100% 33%);
            clip-path: polygon(50% 0, 0 33%, 100% 33%);
    transform: rotate(180deg);
    background-color: #f85252; }
  .head_btn_indicator.indicator_active {
    opacity: 1;
    transform: translateY(0); }

div .app_head_popover {
  overflow: inherit;
  background: transparent;
  margin-top: 20px; }

.app-menu {
  background-color: #292f4c;
  border: 1px solid #373947;
  padding: 8px 0;
  position: relative;
  border-radius: 6px;
  z-index: 999; }
  .app-menu.main_app {
    width: 165px; }
    .app-menu.main_app hr {
      background-color: rgba(255, 255, 255, 0.1);
      height: 1px;
      width: calc(100% - 32px);
      margin: 8px auto; }
  .app-menu:before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    right: 25px;
    top: -8px;
    border-left: 1px solid #373947;
    border-top: 1px solid #373947;
    background-color: #292f4c;
    transform: rotate(45deg) skew(-20deg, -20deg);
    z-index: 9999; }
  .app-menu a,
  .app-menu button {
    padding: 8px 16px;
    text-align: left;
    width: 100%;
    font-size: 14px;
    display: block;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.3s linear; }
    .app-menu a:hover,
    .app-menu button:hover {
      background-color: rgba(255, 255, 255, 0.1); }

.language_menu {
  padding: 10px 16px 3px;
  background: #0e1832;
  border: 1px solid #373947;
  box-sizing: border-box;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.25);
  position: relative;
  border-radius: 6px;
  z-index: 999; }
  .language_menu a,
  .language_menu button {
    padding: 12px 0;
    text-align: left;
    width: 100%;
    font-size: 16px;
    display: block;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.3s linear; }
    .language_menu a:hover,
    .language_menu button:hover {
      background-color: rgba(255, 255, 255, 0.1); }
  .language_menu button:first-of-type {
    border-bottom: 1px solid #373947; }
  .language_menu .app-header-check-mark {
    margin-right: 8px;
    height: 11px;
    width: 9px; }
  .language_menu .language-title {
    font-size: 14px;
    color: #ffffff; }

.language_popover {
  min-width: 232px !important;
  padding-bottom: 0 !important; }

.notification_popover {
  width: 360px !important;
  height: 164px !important;
  background: #dadada !important;
  border: 1px solid #ffffff !important;
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 6px !important;
  padding-bottom: 0 !important; }
  .notification_popover > div {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 18px 25px 0 34px;
    color: #000000;
    height: 125px; }
    .notification_popover > div .notification_header {
      font-size: 16px;
      display: flex;
      line-height: 30px; }
      .notification_popover > div .notification_header .notification_icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #f32a2a;
        margin-right: 15px;
        text-align: center; }
    .notification_popover > div .notification_text {
      font-size: 14px;
      margin: 10px 0; }
    .notification_popover > div .notification_buttons {
      display: flex;
      margin-left: 105px;
      justify-content: space-between; }

.notification-icon {
  width: 25px;
  height: 25px; }

.download__buttons_app {
  margin-right: 25px; }

div .tabs_buttons_container {
  position: relative; }
  div .tabs_buttons_container:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: rgba(255, 255, 255, 0.2); }

div .tab_wrapper {
  font-family: "Inter", sans-serif;
  text-transform: inherit;
  font-size: 16px;
  letter-spacing: -0.5px;
  opacity: 0.5;
  font-weight: 400;
  min-height: 52px;
  min-width: 60px;
  max-width: unset;
  transition: all 0.2s linear; }
  div .tab_wrapper:hover {
    opacity: 1; }
  div .tab_wrapper.tab_selected {
    opacity: 1; }

.public_page_wrapper {
  min-height: 100vh; }
  .public_page_wrapper .public_content h1 {
    color: white;
    font-weight: bold;
    max-width: 65%;
    margin: 0.7em 0;
    line-height: 1.1em;
    font-size: 3.5rem; }
    .public_page_wrapper .public_content h1.no_max_width {
      max-width: 100%; }
  .public_page_wrapper .public_content p {
    font-size: 1rem;
    margin: 1em 0; }
    .public_page_wrapper .public_content p.align_right {
      width: 100%;
      text-align: right; }
  .public_page_wrapper .public_content h3 {
    color: white;
    margin: 0.7em 0 0.7em 0;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.75rem; }
  .public_page_wrapper .public_content ul {
    margin-top: 0;
    margin-bottom: 1rem; }
    .public_page_wrapper .public_content ul li {
      display: list-item;
      margin: 1em 0;
      margin-left: 1rem;
      font-size: 1rem;
      list-style: decimal; }
      .public_page_wrapper .public_content ul li ol {
        margin-top: 0;
        margin-bottom: 1rem; }
        .public_page_wrapper .public_content ul li ol li {
          display: list-item;
          margin: 1em 0;
          margin-left: 1rem;
          font-size: 1rem;
          list-style: disc; }

.for_some {
  padding: 80px 15vw; }

.hero_title {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 48px; }

.hero_video-wrapper {
  width: 100%;
  border-radius: 16px;
  padding-bottom: 116px; }
  .hero_video-wrapper .hero_video {
    width: 100%;
    height: 625px;
    opacity: 0.62;
    border-radius: 16px; }

.download_title {
  font-size: 36px;
  max-width: 800px;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 32px; }

.download-wrapper {
  text-align: center; }

.download_subtitle {
  font-weight: 500;
  margin-bottom: 32px; }

.features_title {
  font-weight: 500;
  text-align: center;
  margin-bottom: 56px;
  font-size: 36px; }

.features_section {
  padding-bottom: 120px; }

.download_section {
  padding-bottom: 152px; }

.features__items {
  display: flex; }
  .features__items .features__item--outer {
    flex-basis: 33.3%;
    min-height: 263px;
    margin-right: 32px;
    margin-bottom: 32px;
    background: #192036;
    border-radius: 9px; }
    .features__items .features__item--outer .features__item--inner {
      padding: 40px 25px 25px;
      text-align: center; }
      .features__items .features__item--outer .features__item--inner .features__item_icon {
        margin-bottom: 16px; }
      .features__items .features__item--outer .features__item--inner .features__item_title {
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 24px; }
      .features__items .features__item--outer .features__item--inner .features__item_text {
        padding: 0 10px;
        font-weight: normal;
        font-size: 14px;
        color: #737a93; }

.stars-wrapper {
  text-align: center; }

.stars_title {
  font-weight: 500;
  text-align: center;
  margin-bottom: 40px;
  font-size: 36px; }

.stars_subtitle {
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #a0a6d0;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 55px; }

.stars__buttons_app {
  margin-right: 25px; }

@media screen and (max-width: 1300px) {
  .public_page_wrapper .public_content {
    box-sizing: border-box;
    width: calc(100vw - 32px);
    margin: 0 16px; }
  .hero_title {
    margin-top: 20px;
    font-size: 32px; }
  .for_some {
    padding: 50px 0; }
  .public_head {
    padding: 0 20px !important; }
  .download_section {
    padding-bottom: 116px;
    margin: 0 16px; }
  .features_section {
    padding-bottom: 90px; }
  .features__items {
    flex-direction: column;
    margin: 0 auto;
    max-width: 400px; }
    .features__items .features__item--outer {
      flex-basis: 100%;
      margin: 0 16px 32px 16px; }
  .stars_title,
  .features_title,
  .download_title {
    font-size: 30px; }
  .stars-wrapper {
    padding: 0 10px; }
  .hero_video-wrapper {
    width: 100%; }
    .hero_video-wrapper .hero_video {
      width: 100%;
      height: 200px; }
  .download__buttons {
    display: flex;
    flex-flow: column; }
    .download__buttons .download__buttons_app {
      margin-right: 0;
      margin-bottom: 25px; }
  .stars__buttons {
    display: flex;
    flex-flow: column; }
    .stars__buttons .download__buttons_app {
      margin-right: 0;
      margin-bottom: 25px; } }

.public_head {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 50px;
  background-color: #0e1832;
  padding: 0 15vw;
  z-index: 99; }
  .public_head.affiliate_padding {
    padding: 0 132px; }
  .public_head > img {
    cursor: pointer; }
  .public_head a {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px; }
  .public_head .header-options {
    display: flex;
    align-items: center;
    color: #ffffff;
    min-width: unset; }
    .public_head .header-options svg {
      fill: #ffffff; }
    .public_head .header-options button {
      display: flex;
      min-width: 125px !important;
      width: unset;
      max-width: 125px !important; }
      .public_head .header-options button p {
        color: #ffffff;
        font-family: "Inter", sans-serif;
        font-size: 13px;
        margin-right: 10px;
        text-transform: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
    .public_head .header-options .user_avatar {
      width: 24px;
      height: 24px; }
    .public_head .header-options .app-menu_button {
      background: #232841;
      border-radius: 16px; }
      .public_head .header-options .app-menu_button svg {
        font-size: 14px;
        margin-left: 5px; }
      .public_head .header-options .app-menu_button.menu_open svg {
        transform: rotate(180deg); }
    .public_head .header-options .language_button {
      padding: 0 9px; }
      .public_head .header-options .language_button img {
        margin-right: 5px; }

.header-logo > :first-child {
  margin-right: 20px; }

@media screen and (max-width: 420px) {
  .public_head {
    height: 60px; }
  .header-options {
    flex-flow: column; }
    .header-options a {
      font-size: 12px;
      margin-bottom: 10px; } }

.auth_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, #000b47 0%, #0d146e 100%); }
  .auth_container.login_page {
    align-items: unset; }
  .auth_container.auth-login-form .inputs_wrapper {
    margin: 0 auto;
    max-width: 380px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .auth_container.auth-login-form .inputs_wrapper .custom_input_wrapper {
      width: 100%; }
      .auth_container.auth-login-form .inputs_wrapper .custom_input_wrapper:first-of-type {
        margin-right: 12px; }
    .auth_container.auth-login-form .inputs_wrapper.with-postcode .custom_input_wrapper:first-of-type {
      width: 150px;
      max-width: 150px; }
    .auth_container.auth-login-form .inputs_wrapper.with-postcode .custom_input_wrapper:last-of-type {
      width: 218px;
      max-width: 218px; }
  .auth_container.auth-login-form .select_wrapper {
    margin: 0 auto;
    max-width: 380px;
    width: 100%;
    margin-bottom: 20px;
    height: 51px;
    position: relative; }
    .auth_container.auth-login-form .select_wrapper .select_component .select__control .select__placeholder {
      font-size: 14px;
      line-height: 17px; }
    .auth_container.auth-login-form .select_wrapper .select_component .select__control {
      background-color: rgba(0, 0, 0, 0.09); }
    .auth_container.auth-login-form .select_wrapper .select_component .select__control .select__value-container {
      margin-top: 16px; }
    .auth_container.auth-login-form .select_wrapper input {
      width: unset; }
    .auth_container.auth-login-form .select_wrapper .select-label {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      color: rgba(255, 255, 255, 0.72);
      font-size: 14px;
      opacity: 0.5;
      pointer-events: none;
      transition: 0.2s ease all;
      transform: translate(14px, 4px) scale(0.75); }

.auth_section {
  padding: 128px 0 50px;
  width: 55%;
  min-height: 100vh;
  background-color: #1b2664;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }

.round_inside_block {
  background: linear-gradient(180deg, #000b47 0%, #0d146e 100%);
  -webkit-clip-path: ellipse(60% 53% at 30% 50%);
          clip-path: ellipse(60% 53% at 30% 50%);
  left: -10%;
  top: 0;
  height: 100%;
  position: absolute;
  width: 20%; }

.auth_center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh; }

.auth_section_block {
  max-width: 380px; }
  .auth_section_block .tab_wrapper {
    font-size: 14px; }
  .auth_section_block form {
    margin-top: 24px; }
    .auth_section_block form .custom_input_wrapper {
      margin-bottom: 20px; }
    .auth_section_block form .inputs_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .auth_section_block form .inputs_wrapper .custom_input_wrapper {
        width: 100%; }
        .auth_section_block form .inputs_wrapper .custom_input_wrapper:first-of-type {
          margin-right: 12px; }
      .auth_section_block form .inputs_wrapper.with-postcode .custom_input_wrapper:first-of-type {
        width: 150px;
        max-width: 150px; }
      .auth_section_block form .inputs_wrapper.with-postcode .custom_input_wrapper:last-of-type {
        width: 218px;
        max-width: 218px; }
    .auth_section_block form .select_wrapper {
      margin-bottom: 16px;
      width: 100%;
      height: 51px;
      position: relative; }
      .auth_section_block form .select_wrapper .select_component .select__control .select__placeholder {
        font-size: 14px;
        line-height: 17px; }
      .auth_section_block form .select_wrapper .select_component .select__control {
        background-color: rgba(0, 0, 0, 0.09); }
      .auth_section_block form .select_wrapper .select_component .select__control .select__value-container {
        margin-top: 16px; }
      .auth_section_block form .select_wrapper input {
        width: unset; }
      .auth_section_block form .select_wrapper .select-label {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        color: rgba(255, 255, 255, 0.72);
        font-size: 14px;
        opacity: 0.5;
        pointer-events: none;
        transition: 0.2s ease all;
        transform: translate(14px, 4px) scale(0.75); }

.auth-label-with-tooltip {
  display: flex;
  align-items: center;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
          column-gap: 4px; }
  .auth-label-with-tooltip img {
    cursor: pointer;
    width: 21px;
    height: 21px;
    pointer-events: visible; }

.auth_title {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 32px; }

.auth_subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 39px; }
  .auth_subtitle strong {
    color: white;
    font-weight: normal; }

.auth_subtitle_email {
  color: #737a93;
  max-width: 500px !important;
  margin: 0 auto 27px !important; }

.auth_promo_wrapper {
  padding: 128px 0 50px;
  width: 45%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.auth_promo {
  z-index: 20;
  position: relative;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .auth_promo .payment_logos {
    margin-top: 30px; }
  .auth_promo .download__buttons {
    margin-top: 32px; }

.promo_image {
  position: relative;
  height: 520px; }
  .promo_image img {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-height: 100%; }

.promo_text {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.3px;
  max-width: 440px; }

.promo_text_bottom {
  margin-top: 46px; }

.en_video {
  width: 100%;
  min-height: 300px; }

div .play_button {
  height: 106px;
  width: 106px;
  background-color: #ffffff;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  left: calc(50% - 53px);
  position: absolute;
  z-index: 3; }
  div .play_button:hover {
    background-color: rgba(255, 255, 255, 0.7); }
  div .play_button.login_play_button {
    top: calc(53% - 53px); }
  div .play_button.register_play_button {
    top: calc(53% - 53px); }
  div .play_button.recovery_play_button {
    top: calc(50% - 53px);
    left: calc(51% - 53px); }

.auth_buttons_wrapper {
  margin: 24px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  .auth_buttons_wrapper .register_button {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content; }
    .auth_buttons_wrapper .register_button .no_deposit_required {
      margin-top: 12px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #a0a6d0; }
  .auth_buttons_wrapper .auth_button_wrapper {
    display: inline-block; }
    .auth_buttons_wrapper .auth_button_wrapper .auth_button {
      min-width: 180px !important; }

.auth_nav_link {
  color: #a0a6d0;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  line-height: 15px;
  text-align: right; }
  .auth_nav_link:hover {
    text-decoration: none; }

.auth_link {
  color: #a0a6d0;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-decoration: none; }
  .auth_link:hover {
    text-decoration: underline; }

.auth_login_options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 24px; }
  .auth_login_options a {
    font-size: 12px !important; }

.recovery_buttons_wrapper .auth_button_wrapper {
  margin-right: 50px; }

.auth_steps_box {
  margin-top: 128px;
  min-height: 360px;
  min-width: 558px;
  border-radius: 13px;
  background-color: #0e1832;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.25);
  text-align: center; }
  .auth_steps_box .auth_title {
    font-size: 38px; }
  .auth_steps_box .auth_subtitle {
    font-size: 16px; }
  .auth_steps_box .auth_button_wrapper {
    margin-bottom: 22px; }

.recovery_first_step_wrapper {
  padding: 71px 0 34px; }
  .recovery_first_step_wrapper .custom_input_wrapper {
    margin-bottom: 35px; }

.code_confirm_wrapper {
  padding: 43px 0 43px; }
  .code_confirm_wrapper img {
    margin-bottom: 34px; }
  .code_confirm_wrapper .custom_input_wrapper {
    margin-bottom: 24px; }
  .code_confirm_wrapper .auth_subtitle {
    margin-bottom: 27px; }

.facebook-auth_wrapper {
  padding: 37px 0 34px; }
  .facebook-auth_wrapper .auth_subtitle {
    max-width: 500px !important;
    margin: 0 auto 39px !important; }
  .facebook-auth_wrapper .custom_input_wrapper {
    margin-bottom: 20px; }

.set_password_wrapper {
  padding: 54px 0 40px; }
  .set_password_wrapper img {
    margin-bottom: 12px; }
  .set_password_wrapper .custom_input_wrapper {
    margin-bottom: 18px; }
  .set_password_wrapper .auth_button_wrapper {
    margin-bottom: 0; }

.resend_wrapper {
  position: relative;
  display: inline-block;
  color: #737a93; }
  .resend_wrapper .break-line {
    height: 25px; }
  .resend_wrapper div {
    margin-top: 18px;
    margin-bottom: 18px; }
  .resend_wrapper span {
    color: #737a93; }

.success_resend {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  border-radius: 50%;
  padding: 2px;
  position: absolute;
  right: -30%;
  top: calc(50% - 11px); }
  .success_resend svg {
    fill: #ffffff;
    font-size: 18px; }

.logo_console {
  width: 50px;
  height: 50px; }
  .logo_console.width-18 {
    width: 18px;
    height: 18px; }
  .logo_console.width-62 {
    width: 62px;
    height: 62px; }

.scroll-down-btn {
  position: fixed !important;
  right: 10px;
  bottom: 10px;
  width: 180px; }
  .scroll-down-btn img {
    height: 100%;
    width: 100%; }

.deposit-to-play-dialog {
  text-align: center;
  padding: 24px 0; }
  .deposit-to-play-dialog__title {
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px; }
  .deposit-to-play-dialog__description {
    margin-bottom: 48px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px; }
  .deposit-to-play-dialog .or_separator > span {
    background: #0e1832; }
  .deposit-to-play-dialog .default_button,
  .deposit-to-play-dialog .auth_button {
    border-radius: 22.5px !important;
    width: 186px !important;
    min-width: 186px !important;
    height: 45px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    text-transform: none !important; }

.balance_green {
  color: #2fc272;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px; }

.input-field-with-currency {
  width: 100%;
  position: relative; }
  .input-field-with-currency__currency {
    position: absolute;
    right: 8px;
    top: 16px;
    font-weight: 600;
    font-size: 14px;
    opacity: 0.5; }

@-webkit-keyframes back_link {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0); } }

@keyframes back_link {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0); } }

@-webkit-keyframes skip_link {
  0% {
    transform: translateX(0) rotate(180deg); }
  50% {
    transform: translateX(10px) rotate(180deg); }
  100% {
    transform: translateX(0) rotate(180deg); } }

@keyframes skip_link {
  0% {
    transform: translateX(0) rotate(180deg); }
  50% {
    transform: translateX(10px) rotate(180deg); }
  100% {
    transform: translateX(0) rotate(180deg); } }

@-webkit-keyframes search_block {
  0% {
    transform: translate(-20px, 0px); }
  25% {
    transform: translate(0px, -20px); }
  50% {
    transform: translate(20px, 0px); }
  75% {
    transform: translate(0px, 20px); }
  100% {
    transform: translate(-20px, 0px); } }

@keyframes search_block {
  0% {
    transform: translate(-20px, 0px); }
  25% {
    transform: translate(0px, -20px); }
  50% {
    transform: translate(20px, 0px); }
  75% {
    transform: translate(0px, 20px); }
  100% {
    transform: translate(-20px, 0px); } }

.is_loading {
  position: relative;
  overflow: hidden; }
  .is_loading .impulse {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    transform: rotate(45deg);
    background: #bdbdbd;
    -webkit-animation-name: moveit;
            animation-name: moveit;
    -webkit-animation: moveit 1s infinite;
            animation: moveit 1s infinite;
    box-shadow: 0 0 50px 14px rgba(255, 255, 255, 0.2); }

@-webkit-keyframes moveit {
  from {
    left: -100;
    top: -100%; }
  to {
    left: 300%;
    top: 300%; } }

@keyframes moveit {
  from {
    left: 0;
    top: 0; }
  to {
    left: 300%;
    top: 300%; } }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/Inter-Light-BETA.42922cef.woff2) format("woff2"), url(/static/media/Inter-Light-BETA.235041ef.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Inter-Regular.bffaed79.woff2) format("woff2"), url(/static/media/Inter-Regular.aebfbb3c.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/Inter-Italic.381444ec.woff2) format("woff2"), url(/static/media/Inter-Italic.35cf8109.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/Inter-Medium.2e5e0884.woff2) format("woff2"), url(/static/media/Inter-Medium.c09fb389.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  src: url(/static/media/Inter-MediumItalic.7a7fd735.woff2) format("woff2"), url(/static/media/Inter-MediumItalic.ad6e093c.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url(/static/media/Inter-SemiBold.cd04cacd.woff2) format("woff2"), url(/static/media/Inter-SemiBold.acc6eebd.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  src: url(/static/media/Inter-SemiBoldItalic.57262d35.woff2) format("woff2"), url(/static/media/Inter-SemiBoldItalic.569061c2.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/Inter-Bold.34356f6b.woff2) format("woff2"), url(/static/media/Inter-Bold.61c493e3.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  src: url(/static/media/Inter-BoldItalic.57acb479.woff2) format("woff2"), url(/static/media/Inter-BoldItalic.6fbcf86a.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url(/static/media/Inter-ExtraBold.a627e2ce.woff2) format("woff2"), url(/static/media/Inter-ExtraBold.0b03428a.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 800;
  src: url(/static/media/Inter-ExtraBoldItalic.b95f0df1.woff2) format("woff2"), url(/static/media/Inter-ExtraBoldItalic.137fcd98.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/Inter-Black.c7a3bad1.woff2) format("woff2"), url(/static/media/Inter-Black.a4502329.woff) format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  src: url(/static/media/Inter-BlackItalic.d000d76e.woff2) format("woff2"), url(/static/media/Inter-BlackItalic.094751f4.woff) format("woff"); }

.loader_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }

.loader_container {
  position: absolute;
  margin: 0 auto;
  top: 170px; }

.loading {
  position: absolute;
  top: 80px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 24px; }
  .loading::after {
    content: '.';
    -webkit-animation: dots 0.5s steps(5, end) infinite;
            animation: dots 0.5s steps(5, end) infinite; }

@-webkit-keyframes dots {
  0%, 20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0); }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0); }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0); }
  80%, 100% {
    text-shadow: .25em 0 0 white, .5em 0 0 white; } }

@keyframes dots {
  0%, 20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0); }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0); }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0); }
  80%, 100% {
    text-shadow: .25em 0 0 white, .5em 0 0 white; } }

